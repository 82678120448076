<template>
  <div v-if="!to" class="dropdown-item">
    <slot />
  </div>
  <a
    v-else
    class="dropdown-item"
    :href="to"
    :target="openLinkInNewTab ? '_blank' : '_self'"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'DropdownItem',
  props: {
    to: {
      type: [String, undefined],
      default: undefined
    },
    openLinkInNewTab: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/variables";
.dropdown-item {
  min-width: 166px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-items: flex-start;
  color: #1F2937;
  font-weight: 500;
  padding: 13px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    background-color: $normal-color;
  }
}
</style>
