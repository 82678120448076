<template>
  <div v-click-outside="hide" class="dropdown-wrapper" @click="toggle">
    <slot name="toggler">
      <button>Toggle</button>
    </slot>
    <transition name="dropdown-content">
      <div
        v-if="open"
        class="dropdown-menu"
        :class="layout === 'left' ? 'dropdown-menu-left' : 'dropdown-menu-right'"
        :style="{top: `${togglerHeight + 4}px`}"
      >
        <slot name="menu-items" />
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'Dropdown',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    layout: {
      type: String
    }
  },
  data () {
    return {
      open: false,
      togglerHeight: 0
    }
  },
  mounted () {
    this.togglerHeight = this.$slots.toggler[0].elm.offsetHeight
  },
  methods: {
    toggle () {
      this.open = !this.open
    },
    hide () {
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/variables";
.dropdown-wrapper {
  position: relative
}

.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.dropdown-menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  position: absolute;
  min-width: 182px;
  background: $white;
  padding: 8px;
  z-index: 99;

  border: 1px solid $container-border-color;
  box-shadow: $option-outer-box-shadow;
  border-radius: 12px;
}

.dropdown-menu-left {
  left: 0px;
}

.dropdown-menu-right {
  right: 0px;
}

</style>
