const RPCApiErrorMap = {
  rpcApiErrNotOpenForRegistration: 30001,
  rpcApiErrAccountNotExist: 30003,
  rpcApiErrOrderStatusExpired: 30008,
  rpcApiErrAccountCharsErr: 30015,
  rpcApiErrSystemUpgrade: 30019,
  rpcApiErrAccountFrequencyLimit: 11013,
  rpcApiErrAccountIsExpired: 30010,
  rpcApiErrNotHaveOwnerPermission: 30011,
  rpcApiErrTransferAccountSameAddress: 30023,
  rpcApiErrWithdrawFromDasLockInsufficientBalance: 30025,
  rpcApiErrWithdrawFromDasLockUnsupportedLongAddress: 30030,
  rpcApiErrLockAccountNotExist: 30032,
  rpcApiErrSignatureError: 30034,
  rpcApiErrExpiredAtCanNotLock: 31002,
  apiErrorCodeInsufficientBalance: 11007,
  apiErrorTxExpired: 11008,
  apiErrorCodeResolveFailed: 11011,
  apiErrorCodeNotEnoughChange: 11014,
  apiErrorCodeNotExitsTrx: 11001,
  apiErrorCodeReverseAlreadyExist: 12001,
  apiErrorCodeCouponNotFound: 30038,
  apiErrorCodeCouponUsed: 30039,
  apiErrorCodeCouponEventDidNotStart: 30040,
  apiErrorCodeAccountOnCrossChain: 30041,
  apiErrorCodeNoAccountAccess: 30042,
  apiErrorOrderNotExist: 30006,
  apiErrorAccountLengthErr: 30014,
  apiErrorAccountRegistering: 30004,
  apiErrorReverseAlreadyExist: 10000
}

const WalletErrorMap = {
  // MetaMask
  metaMaskWalletRequestPermissions: -32002,
  metaMaskUserRejectedAccountAccess: -32603,
  metaMaskUserDeniedMessageSignature: 4001,
  // WalletConnect
  walletConnectUserRejectedTheTransaction: 'User rejected the transaction',
  walletConnectInsufficientFundsForTransfer: 'insufficient funds for transfer',
  // TronLink
  tronLinkConfirmationDeclinedByUser: 'Confirmation declined by user',
  tronLinkInsufficientBalance: 'class org.tron.core.exception.ContractValidateException : Validate TransferContract error, no OwnerAccount.',
  tronLinkTypeErrorAddUpdateDataNotFunction: 'transactionBuilder.addUpdateData',
  tronLinkAuthorizationRequestsAreBeingProcessed: 4000,
  // imToken
  imTokenUserCanceled: 'user_canceled',
  // coinbase wallet
  coinbaseWalletUsingMultipleWallet: 'Request method eth_chainId is not supported',
  // Portal Wallet
  portalWalletInsufficientBalance: 'input capacity not enough',
  portalWalletValidationFailure: 'ValidationFailure(-31)'
}

const errorMap = {
  success: 0,
  serverError: 500,
  networkError: -1,
  ...WalletErrorMap,
  ...RPCApiErrorMap
}

export default errorMap

export function isUnknownAPIBusinessError (errorNo: number) {
  return !Object.values(errorMap).includes(errorNo)
}

function swapKeysAndValues (obj: Object) {
  const swapped = Object.entries(obj).map(
    ([key, value]) => [value, key]
  )

  return Object.fromEntries(swapped)
}

export function getErrorName (errorNo: number) {
  const errorNameMap = swapKeysAndValues(errorMap)
  return errorNameMap[errorNo]
}
